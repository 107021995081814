'use client';

import { Fragment, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import * as yup from 'yup';

import { signIn } from '@/app/actions/auth';
import type {
  SessionList,
  SessionsReachedResponse,
  SignInResponse,
} from '@/app/actions/auth/types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { UserApi } from '@/api/domains/user-api';

import { HTTP_CODE_SUCCESS } from '@/constants/http-code.constants';

import { Button } from '@/components/Buttons';
import { Form } from '@/components/Form';
import { SessionsModal } from '@/components/SessionsModal';

import { useNotification } from '@/contexts/NotificationContext';
import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerification.module.scss';

// =================================================================

type LoginFormType = {
  username: string;
  password: string;
  shouldRemember: boolean;
};

// =================================================================

export const LoginWithUsername = () => {
  const { authCallbackOnSuccess, setAccessToken, setUserCredentials } = useVerification();
  const t = useTranslations();
  const { notification } = useNotification();

  const [sessions, setSessions] = useState<SessionList[]>([]);
  const [formData, setFormData] = useState<LoginFormType>({
    username: '',
    password: '',
    shouldRemember: true,
  });

  const schema = useMemo(
    () =>
      yup.object({
        username: yup.string().trim().required(t('formWarningMassage.requiredField')),
        password: yup.string().required(t('formWarningMassage.requiredField')),
        shouldRemember: yup.boolean().required(),
      }),
    [t],
  );

  const methods = useForm<LoginFormType>({
    defaultValues: formData,
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<LoginFormType> = async data => {
    try {
      setFormData(data);

      const response = await UserApi.signIn(data);

      const { cineramaToken, me } = response.data as SignInResponse;
      const haveSessions = response?.data?.sessionList?.length > 0;

      if (haveSessions) {
        const { sessionList = [] } = response.data as unknown as SessionsReachedResponse;
        setSessions(sessionList);
        notification('error', response?.message);
      } else {
        setAccessToken(cineramaToken);
        setUserCredentials(me);
        authCallbackOnSuccess();
      }
    } catch (error) {
      if (!error.response) {
        throw error;
      }
      notification('error', error?.response?.message);
    }
  };

  const handleDeleteSession = async () => {
    const response = await signIn(formData);

    if (response.code === HTTP_CODE_SUCCESS) {
      const { cineramaToken, me } = response.data as SignInResponse;
      setAccessToken(cineramaToken);
      setUserCredentials(me);

      authCallbackOnSuccess();
    }
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form
          isSubmitting={methods.formState.isSubmitting}
          onSubmit={methods.handleSubmit(onSubmit)}
          className={styles.authForm}
        >
          <Form.Body className={styles.authFormBody}>
            <Form.Input
              name="username"
              placeholder={t('verification.login')}
              errorClassName={styles.errorMessage}
              autoFocus
              label={t('verification.loginOrEmailAddress')}
            />
            <Form.Input
              type="password"
              name="password"
              placeholder={t('verification.password')}
              passwordView
              errorClassName={styles.errorMessage}
              label={t('verification.password')}
            />
            <Form.Panel align="flexBetween">
              <Form.Checkbox
                name="shouldRemember"
                label={<Fragment>{t('verification.rememberMe')}</Fragment>}
                variant="checkbox"
              />
            </Form.Panel>
          </Form.Body>
          <Form.Footer className={styles.formFooter}>
            <Button
              variant="primary"
              size="lg"
              as="button"
              type="submit"
              disabled={methods.formState.isSubmitting}
              className={styles.submitButton}
            >
              {t('common.continue')}
            </Button>
          </Form.Footer>
        </Form>
      </FormProvider>
      <SessionsModal
        sessions={sessions}
        onClose={() => setSessions([])}
        onSessionDelete={handleDeleteSession}
      />
    </Fragment>
  );
};
