'use client';

import { useTranslations } from 'next-intl';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import { PhoneForm } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/LoginWithPhone/PhoneForm';
import { SmsForm } from '@/contexts/VerificationContext/VerificationModal/AuthVerification/LoginWithPhone/SmsForm';

export const LoginWithPhone = () => {
  const { hasOtp } = useVerification();
  const t = useTranslations('verification');

  if (hasOtp) {
    return <SmsForm />;
  }

  return <PhoneForm title={t('enterYourPhoneNumber')} />;
};
