import type { CreateSlice } from '@/zustand-store/store.types';

export type PlayerState = {
  isPlayedVideo: boolean;
  setPlayedVideo: (played: boolean) => void;
};

export const createPlayerSlice: CreateSlice<PlayerState> = set => ({
  isPlayedVideo: false,
  setPlayedVideo: played => set(state => ({ ...state, isPlayedVideo: played })),
});
