import { useTranslations } from 'next-intl';

import DoorSVG from '@/icons/DoorSVG';
import { MailOpenedSVG } from '@/icons/MailOpenedSVG';

import { useVerification } from '@/contexts/VerificationContext/useVerification';

import styles from '@/contexts/VerificationContext/VerificationModal/AuthVerification/AuthVerificationHeader.module.scss';

export const AuthVerificationHeader = () => {
  const { hasOtp } = useVerification();

  const t = useTranslations('verification');

  return (
    <div className={styles.authVerificationHeader}>
      <h1 className={styles.authVerificationIcon}>{hasOtp ? <MailOpenedSVG /> : <DoorSVG />}</h1>
      <h1 className={styles.authVerificationTitle}>{hasOtp ? t('placeholderCode') : t('login')}</h1>
      {hasOtp && <p className={styles.authVerificationSubtitle}>{t('enterCodeDescription')}</p>}
    </div>
  );
};
